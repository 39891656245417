import React from "react";
import styled from "styled-components";

const ColumnInner = styled.div`
  display: grid;
  grid-template-columns: [start] 1fr repeat(10, 1fr) 1fr [end];
`;

const ColumnItem = styled.div`
  grid-column-end: ${({ number }) => (number ? number : 10)};
  grid-column-start: ${({ start }) => (start ? start : 1)};
`;

function ColumnWrapper({ number, start, children, ...rest }) {
  return (
    <ColumnInner {...rest}>
      <ColumnItem number={number} start={start}>
        {children}
      </ColumnItem>
    </ColumnInner>
  );
}

export default ColumnWrapper;
