import React from "react";
import { m } from "framer-motion";
import styled from "styled-components";

import MotionHeadline from "./motion/motion-headline";
import ImageBox from "./motion/motion-box/image-box";
import ColumnWrapper from "./globals/column";
import { Wrapper } from "./globals/wrappers";
import Link from "./helpers/link";

import { useProjectTags } from "../hooks/use-project-tags";
import { capitalizeFirstLetter, stringToSlug } from "../functions/util";
import Box from "./motion/motion-box";

const Grid = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 6vw;
`;

const GroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4vw auto;
`;
const TagItem = styled(m.span)`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 1;
  padding: 1.5rem 0;
  color: ${({ theme }) => theme.colors.peach};
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    color ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css} !important;

  a {
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      color ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css} !important;
    color: ${({ theme }) => theme.colors.peach};
  }

  &:hover {
    transform: translateY(-0.1em);
    color: ${({ theme }) => theme.colors.white};

    a {
      color: ${({ theme }) => theme.colors.white};
    }

    .gatsby-image-wrapper {
      opacity: 1;
    }
  }

  p {
    display: inline-flex;
  }

  .subscript {
    position: absolute;
    bottom: -0.1em;
    right: -1.15em;
    margin-bottom: 0;
  }
`;

const TagImage = styled(ImageBox)`
  position: absolute;
  left: 50%;
  top: -15vw;
  width: 40vw;
  height: 40vw;
  border-radius: 30vw;
  overflow: hidden;
  pointer-events: none;

  .gatsby-image-wrapper {
    opacity: 0;
    transition: opacity ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css} !important;
  }
`;

const Lockup = styled.span`
  position: relative;
`;

export function HomeCapabilitiesSection({ data }) {
  const { group } = useProjectTags();
  return (
    <Grid>
      <MotionHeadline text={data?.capabilities_title} className="h6" />
      <ColumnWrapper start={2} number={12}>
        <GroupWrap>
          {group.map(({ fieldValue, totalCount, edges }, i) => {
            return (
              <TagItem key={i}>
                <Lockup>
                  <Link
                    to={`/work?tag=${stringToSlug(fieldValue)}`}
                    hoverData="view-filter-work"
                  >
                    <MotionHeadline
                      text={capitalizeFirstLetter(fieldValue)}
                      staggerChildren={0.05}
                      className="h3"
                    />
                  </Link>
                  <span className="h6 subscript">
                    <Box>{totalCount}</Box>
                  </span>
                </Lockup>

                <TagImage
                  className="tag-image"
                  fluid={edges[0].node.data.marquee_image.fluid}
                />
              </TagItem>
            );
          })}
        </GroupWrap>
      </ColumnWrapper>
    </Grid>
  );
}
