import * as React from "react";
import {
  domAnimation,
  m,
  LazyMotion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import styled from "styled-components";
import { animation } from "../style/theme";

const Wrap = styled(m.div)`
  width: 100%;
  position: relative;

  svg {
    width: 100%;
    position: relative;

    path,
    circle {
      will-change: transform, opacity;
    }
  }
`;

function DisplayLogo({ color = "#E8A791", ...props }) {
  const ref = React.useRef();

  const size = {
    width: 1740,
    height: 378,
    circle: 328,
  };

  const isFirefox = typeof InstallTrigger !== "undefined";
  const isSafari =
    typeof window !== "undefined" &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const cursorY = useMotionValue(0);
  const cursorX = useMotionValue(50);

  React.useEffect(() => {
    if (isFirefox || isSafari) return null;
    const thisRefContainer = ref?.current;

    const moveCursor = (e) => {
      const cursorYCenter = (e.layerY / thisRefContainer.clientHeight) * 100;
      const cursorXCenter = (e.layerX / thisRefContainer.clientWidth) * 100;

      cursorY.set(cursorYCenter);
      cursorX.set(cursorXCenter);
    };
    if (thisRefContainer) {
      thisRefContainer.addEventListener("mousemove", moveCursor);
      return () => {
        thisRefContainer.removeEventListener("mousemove", moveCursor);
      };
    }
  }, [cursorX, cursorY, ref, isFirefox, isSafari]);

  const springConfig = { stiffness: 100, damping: 50, mass: 0.3 };
  const cx = useSpring(cursorX, springConfig);
  const cy = useSpring(cursorY, springConfig);
  const x = useTransform(cx, (value) => `${value}%`);
  const y = useTransform(cy, (value) => `${value}%`);

  const pathAnimation = () => {
    if (isFirefox || isSafari) return null;
    return {
      initial: {
        pathLength: 1,
        pathOffset: 1,
      },
      animate: {
        pathLength: 1,
        pathOffset: 0,
      },
      transition: {
        duration: 2,
        ease: animation.timingFunction.js,
      },
    };
  };

  const orbAnimation = () => {
    if (isFirefox || isSafari) return null;
    return {
      initial: {
        opacity: 0,
        scale: 0.5,
      },
      animate: {
        opacity: 1,
        scale: 1,
      },
      transition: {
        duration: 2,
        delay: 2,
        ease: animation.timingFunction.js,
      },
    };
  };

  return (
    <LazyMotion features={domAnimation}>
      <Wrap>
        <m.svg
          fill="none"
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          viewBox={`0 0 ${size.width} ${size.height}`}
          {...props}
        >
          <mask
            id="prefix__a"
            style={{
              maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 293.386C0 340.039 39.552 378 88.167 378c48.614 0 88.172-37.961 88.178-84.614v-37.699h-37.881v37.699c0 26.61-22.557 48.262-50.292 48.262-27.735 0-50.285-21.652-50.285-48.262V84.609c0-26.616 22.556-48.257 50.285-48.257 13.26 0 26.235 5.145 35.583 14.116 9.49 9.107 14.709 21.23 14.709 34.14v37.699h37.881V84.609c0-22.626-9.171-43.873-25.808-59.843C134.135 9.03 111.407 0 88.167 0 39.552 0 0 37.956 0 84.609v208.777zM992.135 36.638h-27.541L895.471 370.37h-68.582L758.308 36.638h-27.536V370.37h-37.259V7.614h95.581l66.962 333.733h10.26L933.814 7.614h95.586V370.37h-37.259V36.638h-.006zM598.393 7.614l59.399 362.757h-36.717L602.71 260.504H499.572L481.75 370.371h-36.718l59.4-362.757h93.961zM504.432 231.49h93.961L566.534 37.158H536.29L504.432 231.49zm668.648 138.88c64.79 0 90.72-32.127 90.72-106.233V113.847c0-70.477-25.93-106.233-90.72-106.233h-91.27V370.37h91.27zm0-29.024h-54V37.157h54c43.74 0 53.45 29.014 53.45 76.69v150.291c0 50.78-12.96 77.209-53.45 77.209zm186.35 29.024V209.725L1278.97 7.614h38.88l59.94 152.36 60.48-152.36h38.34l-79.92 202.111v160.646h-37.26zm146.19 0h37.26V36.638h29.7l78.3 333.733h89.1V7.614h-37.26v333.733h-23.76L1602.82 7.614h-97.2V370.37zM410.722 113.847c0 58.042-17.822 85.506-54.54 94.833l58.316 161.686h-38.337l-56.702-158.052h-52.926v158.052h-37.796V7.614h91.264c62.102 0 90.721 25.39 90.721 96.387v9.846zM266.538 36.643v146.652h53.468c37.796 0 53.457-18.652 53.457-69.443v-9.846c0-48.19-12.422-67.363-53.457-67.363h-53.468z"
              fill={color}
            />
          </mask>
          <g mask="url(#prefix__a)">
            <mask id="prefix__b" fill={color}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 293.386C0 340.039 39.552 378 88.167 378c48.614 0 88.172-37.961 88.178-84.614v-37.699h-37.881v37.699c0 26.61-22.557 48.262-50.292 48.262-27.735 0-50.285-21.652-50.285-48.262V84.609c0-26.616 22.556-48.257 50.285-48.257 13.26 0 26.235 5.145 35.583 14.116 9.49 9.107 14.709 21.23 14.709 34.14v37.699h37.881V84.609c0-22.626-9.171-43.873-25.808-59.843C134.135 9.03 111.407 0 88.167 0 39.552 0 0 37.956 0 84.609v208.777zM992.135 36.638h-27.541L895.471 370.37h-68.582L758.308 36.638h-27.536V370.37h-37.259V7.614h95.581l66.962 333.733h10.26L933.814 7.614h95.586V370.37h-37.259V36.638h-.006zM598.393 7.614l59.399 362.757h-36.717L602.71 260.504H499.572L481.75 370.371h-36.718l59.4-362.757h93.961zM504.432 231.49h93.961L566.534 37.158H536.29L504.432 231.49zm668.648 138.88c64.79 0 90.72-32.127 90.72-106.233V113.847c0-70.477-25.93-106.233-90.72-106.233h-91.27V370.37h91.27zm0-29.024h-54V37.157h54c43.74 0 53.45 29.014 53.45 76.69v150.291c0 50.78-12.96 77.209-53.45 77.209zm186.35 29.024V209.725L1278.97 7.614h38.88l59.94 152.36 60.48-152.36h38.34l-79.92 202.111v160.646h-37.26zm146.19 0h37.26V36.638h29.7l78.3 333.733h89.1V7.614h-37.26v333.733h-23.76L1602.82 7.614h-97.2V370.37zM410.722 113.847c0 58.042-17.822 85.506-54.54 94.833l58.316 161.686h-38.337l-56.702-158.052h-52.926v158.052h-37.796V7.614h91.264c62.102 0 90.721 25.39 90.721 96.387v9.846zM266.538 36.643v146.652h53.468c37.796 0 53.457-18.652 53.457-69.443v-9.846c0-48.19-12.422-67.363-53.457-67.363h-53.468z"
              />
            </mask>
            <m.path
              d="M0 293.386C0 340.039 39.552 378 88.167 378c48.614 0 88.172-37.961 88.178-84.614v-37.699h-37.881v37.699c0 26.61-22.557 48.262-50.292 48.262-27.735 0-50.285-21.652-50.285-48.262V84.609c0-26.616 22.556-48.257 50.285-48.257 13.26 0 26.235 5.145 35.583 14.116 9.49 9.107 14.709 21.23 14.709 34.14v37.699h37.881V84.609c0-22.626-9.171-43.873-25.808-59.843C134.135 9.03 111.407 0 88.167 0 39.552 0 0 37.956 0 84.609v208.777zM992.135 36.638h-27.541L895.471 370.37h-68.582L758.308 36.638h-27.536V370.37h-37.259V7.614h95.581l66.962 333.733h10.26L933.814 7.614h95.586V370.37h-37.259V36.638h-.006zM598.393 7.614l59.399 362.757h-36.717L602.71 260.504H499.572L481.75 370.371h-36.718l59.4-362.757h93.961zM504.432 231.49h93.961L566.534 37.158H536.29L504.432 231.49zm668.648 138.88c64.79 0 90.72-32.127 90.72-106.233V113.847c0-70.477-25.93-106.233-90.72-106.233h-91.27V370.37h91.27zm0-29.024h-54V37.157h54c43.74 0 53.45 29.014 53.45 76.69v150.291c0 50.78-12.96 77.209-53.45 77.209zm186.35 29.024V209.725L1278.97 7.614h38.88l59.94 152.36 60.48-152.36h38.34l-79.92 202.111v160.646h-37.26zm146.19 0h37.26V36.638h29.7l78.3 333.733h89.1V7.614h-37.26v333.733h-23.76L1602.82 7.614h-97.2V370.37zM410.722 113.847c0 58.042-17.822 85.506-54.54 94.833l58.316 161.686h-38.337l-56.702-158.052h-52.926v158.052h-37.796V7.614h91.264c62.102 0 90.721 25.39 90.721 96.387v9.846zM266.538 36.643v146.652h53.468c37.796 0 53.457-18.652 53.457-69.443v-9.846c0-48.19-12.422-67.363-53.457-67.363h-53.468z"
              stroke={color}
              strokeWidth="5"
              mask="url(#prefix__b)"
              strokeLinecap="round"
              strokeLinejoin="round"
              {...pathAnimation()}
            />
            <g filter="url(#prefix__filter0_f)">
              <m.circle
                cx={0}
                cy={0}
                style={{
                  x: x,
                  y: y,
                }}
                r={size.circle}
                fill={color}
                {...orbAnimation()}
              />
            </g>
          </g>
          <defs>
            <filter
              id="prefix__filter0_f"
              x={0 - 50}
              y={0 - 50}
              width={size.width + 100}
              height={size.height + 100}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation={20}
                result="effect1_foregroundBlur"
              />
            </filter>
          </defs>
        </m.svg>
      </Wrap>
    </LazyMotion>
  );
}

export default DisplayLogo;
