import React from "react";
import { LazyMotion, domAnimation } from "framer-motion";
import styled from "styled-components";

import { Wrapper } from "./globals/wrappers";
import MotionHeadline from "./motion/motion-headline";
import { WorkSlideshow } from "./work-slideshow";
import {
  clampBuilder,
  hoverChange,
  hoverCustomProperty,
} from "../functions/util";
import { CramdynMarquee } from "./home-logo-marquee-wrapper";

const HeadlineWrapper = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 6vw;

  p.h3 {
    font-family: "Orbikular", serif;
    font-size: ${clampBuilder(29 / 16, 80 / 16)};

    > span:first-child {
      padding-left: 16vw;
    }
  }
`;

export function HomeMarquee({ data }) {
  let greeting =
    data.greeting_messages[
      Math.floor(Math.random() * data.greeting_messages.length)
    ];
  return (
    <LazyMotion features={domAnimation}>
      <CramdynMarquee
        data={data}
        onMouseEnter={() => {
          hoverCustomProperty("custom-content", greeting.text);
          hoverChange("text-hover");
        }}
        onMouseLeave={() => {
          hoverCustomProperty(null);
          hoverChange(null);
        }}
      />
      <HeadlineWrapper>
        <MotionHeadline
          text={data.marquee_headline.text}
          className="h3"
          delay={1.25}
        />
      </HeadlineWrapper>
      <WorkSlideshow data={data} />
    </LazyMotion>
  );
}
