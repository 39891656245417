import React from "react";
import { m, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import styled from "styled-components";
import { Wrapper } from "./globals/wrappers";
import Link from "./helpers/link";
import Box from "./motion/motion-box";
import SvgLoader from "./svg";
import { animation } from "../style/theme";
import { hoverChange } from "../functions/util";
import MediaLoader from "./media-loader";

const FeaturedWrapper = styled(Box)`
  height: 45vw;
  min-height: 23rem;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
`;

const FeaturedInner = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  a,
  .marquee-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const Controls = styled(m.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  max-width: 22rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.85rem 1rem;
  color: ${({ theme }) => theme.colors.green};
  background: ${({ theme }) => theme.colors.white};

  a {
    color: ${({ theme }) => theme.colors.green};
  }

  svg path {
    width: 1.5rem;
    height: 1.5rem;
  }

  .project-title {
    font-size: ${({ theme }) => theme.fonts.paragraph};
    margin-bottom: 0;
  }

  .view-link,
  .project-index {
    font-size: ${({ theme }) => theme.fonts.paragraphSmall};
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    bottom: -2px;
    left: -2.5px;
    height: 12.5rem;
    padding: 1.45rem 1.35rem;
    border-radius: 0.25rem;
    border: solid 2.5px ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.green};
    background: ${({ theme }) => theme.colors.white};

    svg path {
      width: 2rem;
      height: 2rem;
    }

    .project-title {
      font-size: ${({ theme }) => theme.fonts.h6};
    }

    .view-link,
    .project-index {
      font-size: ${({ theme }) => theme.fonts.paragraph};
    }
  }
`;

const ControlsRow = styled(m.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  * {
    user-select: none;
  }
`;

const ControlsGroup = styled(m.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  transform: translateX(0.55rem);
`;

const ControlButton = styled(m.div)`
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
    ${({ theme }) => theme.animation.timingFunction.css};

  svg {
    opacity: 1;
    transition: opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  @media (hover: hover) {
    &:hover {
      transform: translateY(-0.1em);

      svg {
        opacity: 0;
      }
    }
  }
`;

const variants = {
  enter: (direction) => {
    return {
      // scale: direction > 0 ? 1.25 : 0.75,
      scale: 1.25,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    scale: 1,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      // scale: direction > 0 ? 1.25 : 0.75,
      scale: 0.75,
      opacity: 0,
    };
  },
};

export function WorkSlideshow({ data }) {
  const [[page, direction], setPage] = React.useState([0, 0]);

  const { featured_work_carousel } = data;
  const docIndex = wrap(0, featured_work_carousel.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      paginate(1);
    }, 10000);

    return () => clearInterval(interval);
  });

  const featured_work = featured_work_carousel[docIndex]?.link?.document;
  const image = featured_work?.data?.marquee_image?.fluid;
  const featured_video = featured_work_carousel[docIndex]?.media_override;
  const video = featured_video?.url;

  return (
    <Wrapper>
      <FeaturedWrapper>
        <AnimatePresence initial={false} custom={direction}>
          {featured_work && (
            <FeaturedInner
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                scale: {
                  ease: animation.timingFunction.js,
                  duration: animation.duration[300].js,
                },
                opacity: {
                  ease: animation.timingFunction.js,
                  duration: animation.duration[200].js,
                },
              }}
            >
              <Link to={`/work/${featured_work?.uid}`} hoverData="view-project">
                <MediaLoader
                  video={video}
                  videoProps={{
                    className: "marquee-image",
                    hiddenStyles: {
                      y: 0,
                    },
                  }}
                  image={image}
                  imageProps={{
                    className: "marquee-image",
                    hiddenStyles: {
                      y: 0,
                    },
                  }}
                />
              </Link>
            </FeaturedInner>
          )}
        </AnimatePresence>
        <Controls>
          <ControlsRow>
            <Link to={`/work/${featured_work?.uid}`}>
              <p className="h6 project-title">{featured_work?.data.title}</p>
            </Link>

            <ControlsGroup>
              <ControlButton
                className="prev"
                onMouseEnter={() => hoverChange("paginate-prev")}
                onMouseLeave={() => hoverChange(null)}
                onClick={() => paginate(-1)}
              >
                <SvgLoader svg="CaretLeft" color="green" />
              </ControlButton>
              <ControlButton
                className="next"
                onMouseEnter={() => hoverChange("paginate-next")}
                onMouseLeave={() => hoverChange(null)}
                onClick={() => paginate(1)}
              >
                <SvgLoader svg="CaretRight" color="green" />
              </ControlButton>
            </ControlsGroup>
          </ControlsRow>
          <ControlsRow>
            <Link to={`/work/${featured_work?.uid}`} className="view-link">
              View Project
            </Link>
            <p className="project-index">
              {docIndex + 1} / {featured_work_carousel.length}
            </p>
          </ControlsRow>
        </Controls>
      </FeaturedWrapper>
    </Wrapper>
  );
}
