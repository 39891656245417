import React from "react";
import styled from "styled-components";
import { Wrapper } from "./globals/wrappers";
import DisplayLogo from "./display-logo";

const LogoMarqueeWrapper = styled(Wrapper)`
  height: 30vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 12vw;
  }
`;
export function CramdynMarquee({ data, fourohfour, color, ...props }) {
  const showLogoMarquee = true || fourohfour;

  return (
    <>
      {showLogoMarquee && (
        <LogoMarqueeWrapper {...props}>
          <DisplayLogo color={color} />
        </LogoMarqueeWrapper>
      )}
    </>
  );
}
