import React from "react";
import MotionHeadline from "./motion/motion-headline";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import ColumnWrapper from "./globals/column";
import Link from "./helpers/link";
import RichText from "./helpers/rich-text";
import { clampBuilder } from "../functions/util";

const Grid = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 6vw;

  .section-title {
    margin: 0 auto 4vw;
  }

  a {
    span {
      transition: opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    }

    &:hover {
      span {
        opacity: 0.5;
      }
    }
  }

  .contact-text {
    margin-top: 4vw;

    p {
      font-size: ${({ theme }) => theme.fonts.h4};
      margin-bottom: 0;
      line-height: 1.5;
      letter-spacing: ${clampBuilder(0.0025, -0.001)};

      a {
        opacity: 0.5;
        text-decoration: none;
        display: inline-block;
        transition: transform
            ${({ theme }) => theme.animation.duration[300].css}
            ${({ theme }) => theme.animation.timingFunction.css},
          opacity ${({ theme }) => theme.animation.duration[100].css}
            ${({ theme }) => theme.animation.timingFunction.css};

        &:hover {
          transform: translateY(-0.1em) !important;
          opacity: 1;
        }
      }
    }
  }
`;

export function ContactSection({ data }) {
  return (
    <Grid>
      <MotionHeadline text="Contact" className="h6 section-title" />
      <ColumnWrapper start={2} number={12}>
        <Link to="/contact" hoverData="link-big-arrow">
          <MotionHeadline
            text="Start a Project"
            className="h1 contact-headline"
          />
        </Link>
      </ColumnWrapper>
      {data.contact_text && (
        <ColumnWrapper className="contact-text" start={2} number={12}>
          <RichText render={data.contact_text.raw} />
        </ColumnWrapper>
      )}
    </Grid>
  );
}
