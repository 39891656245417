import React, { useEffect } from "react";
import { m, LazyMotion, domAnimation, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import MotionHeadline from "./motion/motion-headline";
import Img from "gatsby-image";
import { Wrapper } from "./globals/wrappers";
import ColumnWrapper from "./globals/column";
import useClientLogos from "../hooks/use-client-logos";
import { animation } from "../style/theme";

const Grid = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 6vw;
`;

const ClientLogoGrid = styled(m.div)`
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  margin: 4vw auto;
  gap: 1rem;

  @media ${({ theme }) => theme.device.laptopLg} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const ClientLogo = styled(m.div)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2vw 0;
  padding-right: 4rem;

  .gatsby-image-wrapper {
    width: ${({ width }) => (width * 0.33) / 16}rem;
    height: ${({ height }) => (height * 0.33) / 16}rem;

    @media ${({ theme }) => theme.device.tablet} {
      width: ${({ width }) => (width * 0.66) / 16}rem;
      height: ${({ height }) => (height * 0.66) / 16}rem;
    }

    @media ${({ theme }) => theme.device.desktop} {
      width: ${({ width }) => width / 16}rem;
      height: ${({ height }) => height / 16}rem;
    }
  }
`;

export function ClientsSection() {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });
  const { data } = useClientLogos();

  useEffect(() => {
    if (inView) {
      controls.start("active");
    }
    if (!inView) {
      controls.start("pre");
    }
  }, [controls, inView]);

  const textContainerVariants = {
    pre: {},
    active: {
      transition: {
        staggerChildren: 0.05,
        duration: 1,
        ease: [animation.timingFunction.js],
      },
    },
  };

  const textVariants = {
    pre: {
      opacity: 0,
      rotate: "0deg",
      y: "-25%",
    },
    active: {
      opacity: 1,
      rotate: "0deg",
      y: "0%",
      transition: {
        duration: 1,
        ease: animation.timingFunction.js,
      },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <Grid>
        <MotionHeadline text={data?.clients_title} className="h6" />
        <ColumnWrapper start={2} number={13}>
          <ClientLogoGrid
            ref={ref}
            variants={textContainerVariants}
            initial="pre"
            animate={controls}
          >
            {data?.clients_logos.map(
              (
                {
                  client_logo: {
                    fluid,
                    dimensions: { height, width },
                  },
                },
                i
              ) => {
                return (
                  <ClientLogo
                    key={i}
                    height={height}
                    width={width}
                    variants={textVariants}
                  >
                    <Img fluid={fluid} />
                  </ClientLogo>
                );
              }
            )}
          </ClientLogoGrid>
        </ColumnWrapper>
      </Grid>
    </LazyMotion>
  );
}
