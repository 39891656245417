import React from "react";
import { graphql } from "gatsby";

import { Wrapper } from "../components/globals/wrappers";
import styled from "styled-components";
import { HomeMarquee } from "../components/home-marquee";
import { ClientsSection } from "../components/clients-section";
import { HomeCapabilitiesSection } from "../components/home-capabilities-section";
import { ContactSection } from "../components/contact-section";

/**
 * DIVIDER
 */

export const Grid = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 6vw;
`;

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export function HomeTemplate({
  data: {
    prismicHomepage: { data },
  },
}) {
  return (
    <>
      <HomeMarquee data={data} />
      <ClientsSection data={data} />
      <HomeCapabilitiesSection data={data} />
      <ContactSection data={data} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query HomePageTemplate {
    prismicHomepage {
      uid
      url
      data {
        title
        greeting_messages {
          text
        }
        marquee_headline {
          text
          html
          raw
        }
        featured_work_carousel {
          media_override {
            url
          }
          link {
            uid
            document {
              ... on PrismicProject {
                id
                uid
                type
                data {
                  title
                  marquee_image {
                    fluid(maxWidth: 1800) {
                      ...GatsbyPrismicImageFluid
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
        capabilities_title
        clients_title
        clients_logos {
          client_logo {
            dimensions {
              height
              width
            }
            fluid(maxHeight: 82, maxWidth: 160) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        contact_title
      }
    }
  }
`;

export default HomeTemplate;
