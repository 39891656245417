import { useStaticQuery, graphql } from "gatsby";

export const useProjectTags = () => {
  const data = useStaticQuery(
    graphql`
      query GetProjectsTags {
        allPrismicProject(
          filter: { data: { page_visibility: { ne: false } } }
        ) {
          distinct(field: tags)
          group(field: tags) {
            edges {
              node {
                id
                uid
                type
                data {
                  title
                  marquee_image {
                    fluid(maxWidth: 1800) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
            field
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  return data.allPrismicProject;
};

export default useProjectTags;
