import { useStaticQuery, graphql } from "gatsby";

export const useClientLogos = () => {
  const data = useStaticQuery(
    graphql`
      query GetLogos {
        prismicHomepage {
          data {
            clients_title
            clients_logos {
              client_logo {
                dimensions {
                  height
                  width
                }
                fluid(maxHeight: 82, maxWidth: 160) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    `
  );

  return data.prismicHomepage;
};

export default useClientLogos;
